.menus-list {
  border-bottom: solid 1px $very-dark-gray;

  .menu-item {
    background-color: transparent;
    padding-bottom: 30px;
    padding-left: #{map-get($mobile, 'outer')}px;
    padding-right: #{map-get($mobile, 'outer')}px;
    padding-top: 70px;
    transition: background-color 400ms,
      border-bottom 400ms,
      border-top 400ms,
      padding-bottom 400ms;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      padding-top: 51px;
    }

    &:last-child {
      padding-bottom: 100px;
    }

    &.show-content {
      background-color: $very-light-grayish-orange;
      border-bottom: solid 1px $very-dark-gray;
      border-top: solid 1px $very-dark-gray;
      padding-bottom: 90px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        padding-bottom: 103px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        padding-bottom: 88px;
      }

      &:first-child {
        border-top: solid 1px $very-dark-gray;
      }

      &:last-child {
        border-bottom: none;
      }

      & + .show-content {
        border-top: none;
      }

      .menu-item-head {
        .item-head-content {
          .item-title-container {
            &:hover {
              .main-content,
              .complement {
                color: $slightly-desaturated-red;
              }
            }
          }

          .item-information-container {
            opacity: 0;
          }
        }

        .close-menu-item {
          display: block;
        }
      }

      .menu-item-content {
        max-height: 999em;
        opacity: 1;
      }
    }

    .menu-item-head {
      position: relative;

      .item-head-content {
        margin: 0 auto;
        position: relative;
        width: mobile-grid(10);

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          max-width: desktop-grid(4, (2 * #{map-get($desktop, 'gutter')}));
          width: fit-content;
        }

        .text-details-list {
          height: 112px;
          position: relative;
          width: 100%;

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            bottom: 0;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          }

          .text-details-item {
            @include saa-series-fd;
            align-items: flex-end;
            color: $dark-grayish-orange;
            display: flex;
            font-size: 10px;
            left: 50%;
            letter-spacing: 2.5px;
            line-height: 14px;
            min-height: 28px;
            position: absolute;
            text-align: center;
            top: 0;
            transform: translateX(-50%);
            width: fit-content;

            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              margin-left: 0 !important;
              min-height: 14px;
            }

            &.top-left {
              @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                left: 0;
                padding-right: 62px;
                top: 14px;
                transform: translateX(-100%);
              }

              &:after {
                @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                  left: initial;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }

            &.bottom-left {
              @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                bottom: 14px;
                left: 0;
                padding-right: 62px;
                top: initial;
                transform: translateX(-100%);
              }

              &:after {
                @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                  left: initial;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }

            &.top-right {
              @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                left: initial;
                padding-left: 62px;
                right: 0;
                top: 14px;
                transform: translateX(100%);
              }

              &:after {
                @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%) translateX(calc(50% - 25px));
                }
              }
            }

            &.bottom-right {
              @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                bottom: 14px;
                left: initial;
                padding-left: 62px;
                right: 0;
                top: initial;
                transform: translateX(100%);
              }

              &:after {
                @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%) translateX(calc(50% - 25px));
                }
              }
            }

            &:after {
              background-color: $very-dark-grayish-orange;
              bottom: 0;
              content: '';
              display: block;
              height: 56px;
              left: 50%;
              position: absolute;
              transform: translateX(-50%) translateY(calc(100% + 12px));
              width: 1px;

              @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                height: 1px;
                width: 52px;
              }
            }

            br {
              @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
                content: ' ';
                display: inline;
                padding: 0 3px;
              }
            }
          }
        }

        .item-title-container {
          display: block;
          margin-bottom: 20px;

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            @include z-hills;
            margin-bottom: 64px;
            position: relative;
          }

          &:hover {
            .main-content,
            .complement {
              color: $grayish-lime-green;
            }            
          }

          .main-content,
          .complement {
            color: $slightly-desaturated-red;
            display: block;
            font-size: 36px;
            line-height: 42px;
            margin: 0 auto;
            text-align: center;
            transition: color 400ms;
            width: fit-content;
          }

          .main-content {
            @include tiempos-headline;
          }

          .complement {
            @include tiempos-headline-italic;
          }
        }

        .item-information-container {
          margin: 0 auto;
          opacity: 1;
          text-align: center;
          transition: opacity 400ms;
          width: mobile-grid(10);

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            position: absolute;
            width: fit-content;
          }

          &.top-left {
            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              align-items: flex-end;
              display: flex;
              flex-direction: column;
              left: 0;
              top: 0;
              transform: translateX(-100%);
            }
          }

          &.top-right {
            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              display: flex;
              flex-direction: column;
              right: 0;
              top: 0;
              transform: translateX(100%);
            }
          }

          &.bottom-left {
            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              align-items: flex-end;
              bottom: 0;
              display: flex;
              flex-direction: column;
              left: 0;
              transform: translateX(-100%);
            }
          }

          &.bottom-right {
            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              bottom: 0;
              display: flex;
              flex-direction: column;
              right: 0;
              transform: translateX(100%);
            }
          }

          .item-time-information,
          .item-weekday-information {
            @include saa-series-fd;
            color: $dark-grayish-orange;
            font-size: 10px;
            letter-spacing: 2.5px;
            line-height: 14px;

            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              white-space: nowrap;
              width: fit-content;
            }
          }
        }
      }

      .close-menu-item {
        display: none;
        height: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;

        svg {
          @include responsive-svg;
          fill: $very-dark-grayish-orange;
        }
      }
    }

    .menu-item-content {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 400ms,
        padding-top 400ms,
        opacity 400ms;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        width: desktop-grid(10);
      }

      .left-column-content,
      .right-column-content {
        @media (min-width: #{map-get($tablet, 'width')}px) {
          max-width: tablet-grid(6);
          min-width: tablet-grid(6);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          max-width: desktop-grid(4);
          min-width: desktop-grid(4);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          margin-left: desktop-grid(1, map-get($desktop, 'gutter'));
        }

        .content-title {
          @include saa-series-fd;
          color: $slightly-desaturated-red;
          font-size: 12px;
          letter-spacing: 3px;
          line-height: 16px;
          margin-bottom: 21px;
          margin-top: 80px;
          width: mobile-grid(11);

          @media (min-width: #{map-get($tablet, 'width')}px) {
            width: tablet-grid(5);
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            width: desktop-grid(4);
          }

          &:first-child {
            margin-top: 0;
          }
        }

        .content-item {
          margin-bottom: 22px;
          width: mobile-grid(10);

          @media (min-width: #{map-get($tablet, 'width')}px) {
            width: tablet-grid(5);
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            width: desktop-grid(4);
          }

          &:last-child {
            margin-bottom: 0;
          }

          .content-item-name,
          .content-item-description {
            color: $dark-grayish-orange;
            font-size: 14px;
            line-height: 22px;
          }

          .content-item-name {
            @include tiempos-text;
          }

          .content-item-description {
            @include tiempos-text-italic;
          }
        }
      }

      .left-column-content {
        margin-bottom: 80px;
      }
    }
  }
}
