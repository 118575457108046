.newsletter {
  background-color: $very-light-grayish-orange;
  padding: 100px 0 30px;

  @media (min-width: #{map-get($tablet, 'width')}px) {
    padding: 100px 0 40px;
  }

  @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
    padding: 52px 0 77px;
  }

  .newsletter-container {
    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      display: flex;
      justify-content: space-between;
    }

    .newsletter-content-wrapper {
      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: #{map-get($desktop, 'outer')}px;
        max-width: desktop-grid(6);
        min-width: desktop-grid(6);
      }

      .newsletter-title {
        @include tiempos-headline;
        color: $dark-grayish-orange;
        font-size: 36px;
        line-height: 42px;
        margin: 0 auto 40px;
        text-align: center;
        width: mobile-grid(12);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          width: tablet-grid(6);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          width: desktop-grid(3);
        }

        &:last-child {
          margin: 0 auto;
        }

        i,
        em {
          @include tiempos-headline-italic;
        }
      }

      .newsletter-content {
        @include tiempos-text;
        color: $slightly-desaturated-red;
        font-size: 22px;
        line-height: 28px;
        margin: 0 auto 33px;
        text-align: center;
        width: mobile-grid(12);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          width: tablet-grid(6);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          width: desktop-grid(4);
        }

        &:last-child {
          margin: 0 auto;
        }
      }

      .newsletter-form {
        box-sizing: border-box;
        display: flex;
        margin: 0 auto;
        padding-top: 93px;
        position: relative;
        width: mobile-grid(6, map-get($mobile, 'gutter') * 2);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          width: tablet-grid(3, map-get($tablet, 'gutter'));
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          width: desktop-grid(2, map-get($desktop, 'gutter') * 2);
        }

        &:after {
          background-color: $very-dark-grayish-orange;
          content: '';
          display: block;
          height: 63px;
          left: 50%;
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          width: 1px;
        }

        .newsletter-form-email {
          @include saa-series-fd;
          border-bottom: solid 1px $very-dark-grayish-orange;
          box-sizing: border-box;
          color: $dark-grayish-orange;
          display: block;
          font-size: 10px;
          letter-spacing: 2.5px;
          line-height: 10px;
          padding-bottom: 9px;
          width: mobile-grid(6, map-get($mobile, 'gutter'));
        }

        .newsletter-form-submit {
          border-bottom: solid 1px $very-dark-grayish-orange;
          display: flex;
          height: 25px;
          justify-content: flex-end;
          line-height: 0;
          width: #{map-get($mobile, 'gutter')}px;

          @media (min-width: #{map-get($tablet, 'width')}px) {
            max-width: #{map-get($tablet, 'gutter')}px;
            min-width: #{map-get($tablet, 'gutter')}px;
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            max-width: #{map-get($desktop, 'gutter')}px;
            min-width: #{map-get($desktop, 'gutter')}px;
          }

          svg {
            align-items: flex-end;
            display: block;
            height: auto;
            width: 10px;
          }
        }
      }
    }

    .newsletter-image-container {
      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        max-width: desktop-grid(6, map-get($desktop, 'outer'));
        min-width: desktop-grid(6, map-get($desktop, 'outer'));
      }

      .newsletter-image {
        background-position: center;
        background-size: cover;
        height: 509px;
        margin: 132px auto 0;
        width: mobile-grid(12);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          height: 465px;
          width: tablet-grid(12);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          height: 686px;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}
