#footer {
  background-color: $dark-grayish-orange;
  border-top: solid 1px $very-dark-gray;
  padding-bottom: 100px;
  padding-top: 51px;

  @media (min-width: #{map-get($tablet, 'width')}px) {
    padding-bottom: 110px;
  }

  .footer-title-container {
    margin: 0 auto 42px;
    width: mobile-grid(12);

    .footer-title {
      @include tiempos-text;
      color: $very-soft-orange;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
    }
  }

  .companies-list-carousel {
    border-bottom: solid 1px $very-soft-orange;
    margin-bottom: 50px;
    overflow: hidden;
    padding-bottom: 50px;
    position: relative;
    width: 100vw;

    .companies-list {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        justify-content: space-between;
        padding: 0 #{map-get($tablet, 'outer')}px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        justify-content: center;
        padding: 0 #{map-get($desktop, 'outer')}px;
      }

      .companies-list-item {
        max-width: 100vw;
        min-width: 100vw;

        @media (min-width: #{map-get($tablet, 'width')}px) {
          max-width: fit-content;
          min-width: fit-content;
          transform: none !important;
          transition: none !important;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          margin: 0 40px;
        }

        &.item-clone {
          @media (min-width: #{map-get($tablet, 'width')}px) {
            display: none;
          }
        }

        a {
          @include saa-series-fd;
          color: $very-soft-orange;
          display: block;
          font-size: 20px;
          letter-spacing: 5px;
          line-height: 28px;
          margin: 0 auto;
          text-align: center;
          width: fit-content;
        }
      }
    }

    .carousel-navigation-last,
    .carousel-navigation-next {
      align-items: center;
      display: flex;
      height: 28px;
      justify-content: center;
      position: absolute;
      top: 0;
      width: mobile-grid(1);

      @media (min-width: #{map-get($tablet, 'width')}px) {
        display: none;
      }

      svg {
        fill: $grayish-orange;
        height: auto;
        max-width: 14px;
        width: auto;
      }
    }

    .carousel-navigation-last {
      left: #{map-get($mobile, 'outer')}px;

      svg {
        transform: rotate(180deg);
      }
    }

    .carousel-navigation-next {
      right: #{map-get($mobile, 'outer')}px;
    }
  }

  .address-wrapper {
    margin-bottom: 30px;
    padding-bottom: 94px;
    position: relative;

    &:after {
      background-color: $slightly-desaturated-orange;
      bottom: 0;
      content: '';
      height: 63px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 1px;
    }

    .address-content {
      @include saa-series-fd;
      color: $grayish-red;
      font-size: 14px;
      letter-spacing: 3.5px;
      line-height: 20px;
      margin: 0 auto;
      text-align: center;
      width: mobile-grid(12);

      @media (min-width: #{map-get($tablet, 'width')}px) {
        width: tablet-grid(12);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        width: desktop-grid(12);
      }
    }
  }

  .contact-list {
    margin: 0 auto;
    width: mobile-grid(12);

    @media (min-width: #{map-get($tablet, 'width')}px) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: tablet-grid(12);
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      width: desktop-grid(12);
    }

    .contact-list-item {
      @include saa-series-fd;
      color: $soft-orange;
      font-size: 10px;
      letter-spacing: 2.5px;
      line-height: 14px;
      margin-bottom: 30px;
      text-align: center;
      width: 100%;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        margin-bottom: 0;
        width: fit-content;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin: 0 78px;
      }

      &.social-media-item {
        margin-bottom: 35px;

        @media (min-width: #{map-get($tablet, 'width')}px) {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
