.whats-happening {
  background-color: $very-light-grayish-orange;
  border-bottom: solid 1px $very-dark-gray;
  border-top: solid 1px $very-dark-gray;
  padding: 100px 0 46px;
  width: 100%;

  @media (min-width: #{map-get($tablet, 'width')}px) {
    padding: 45px 0 54px;
  }

  .whats-happening-title {
    @include tiempos-headline;
    color: $dark-grayish-orange;
    font-size: 36px;
    line-height: 42px;
    margin: 0 auto 30px;
    padding-bottom: 85px;
    position: relative;
    text-align: center;
    width: mobile-grid(12);

    @media (min-width: #{map-get($tablet, 'width')}px) {
      margin: 0 auto 24px;
      width: tablet-grid(12);
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      margin: 0 auto 24px;
      width: desktop-grid(12);
    }

    &:after {
      background-color: $very-dark-grayish-orange;
      bottom: 0;
      content: '';
      height: 63px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 1px;
    }

    i,
    em {
      @include tiempos-headline-italic;
    }
  }

  .whats-happening-carousel {
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;
    width: 100%;

    .whats-happening-list {
      display: flex;
      flex-wrap: nowrap;
      transform: none;
      transform: translateX(mobile-grid(-12, map-get($mobile, 'outer') + map-get($mobile, 'gutter') - 1));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        transform: translateX(tablet-grid(-9, map-get($tablet, 'outer') + map-get($tablet, 'gutter')));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        transform: translateX(desktop-grid(0, map-get($desktop, 'outer') + map-get($desktop, 'gutter')));
      }

      .whats-happening-item {
        max-width: mobile-grid(12);
        min-width: mobile-grid(12);
        padding-right: 11px;
        position: relative;

        @media (min-width: #{map-get($tablet, 'width')}px) {
          max-width: tablet-grid(10);
          min-width: tablet-grid(10);
          padding-right: #{map-get($tablet, 'gutter')}px;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          max-width: desktop-grid(4);
          min-width: desktop-grid(4);
          padding-right: #{map-get($desktop, 'gutter')}px;
        }

        .whats-happening-item-image {
          align-items: center;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          display: block;
          height: mobile-grid(12);
          width: 100%;

          @media (min-width: #{map-get($tablet, 'width')}px) {
            height: tablet-grid(10);
            margin-right: #{map-get($tablet, 'gutter')}px;
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            height: desktop-grid(4);
            margin-right: #{(map-get($desktop, 'gutter'))}px;
          }
        }

        .whats-happening-item-informations {
          padding-top: 40px;

          .whats-happening-item-date {
            @include saa-series-fd;
            color: $dark-grayish-orange;
            font-size: 10px;
            letter-spacing: 2.5px;
            line-height: 14px;
            margin: 0 auto 22px;
            text-align: center;
            width: mobile-grid(8);

            @media (min-width: #{map-get($tablet, 'width')}px) {
              width: tablet-grid(4);
            }

            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              width: desktop-grid(3);
            }
          }

          .whats-happening-item-name {
            @include tiempos-text;
            color: $dark-grayish-orange;
            font-size: 22px;
            line-height: 28px;
            margin: 0 auto;
            min-height: 58px;
            text-align: center;
            width: mobile-grid(8, map-get($mobile, 'gutter') * 2);

            @media (min-width: #{map-get($tablet, 'width')}px) {
              width: tablet-grid(4);
            }

            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              width: desktop-grid(3);
            }

            i,
            em {
              @include tiempos-text-italic;
            }
          }
        }
      }
    }

    .carousel-navigation-last,
    .carousel-navigation-next {
      background: transparent;
      bottom: 18.5px;
      display: block;
      line-height: 0;
      position: absolute;
      text-align: right;
      width: auto;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        width: desktop-grid(3, map-get($desktop, 'outer'));
      }

      svg {
        display: inline-block;
        fill: $dark-grayish-orange;
        height: auto;
        width: 32px;
      }
    }

    .carousel-navigation-last {
      left: #{map-get($mobile, 'outer')}px;
      transform: rotate(180deg);

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: #{map-get($tablet, 'outer')}px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: #{map-get($tablet-landscape, 'outer')}px;
      }
    }

    .carousel-navigation-next {
      right: #{map-get($mobile, 'outer')}px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        right: #{map-get($tablet, 'outer')}px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        right: #{map-get($tablet-landscape, 'outer')}px;
      }
    }
  }

  .whats-happening-carousel-navigation-list {
    display: flex;
    display: none;
    justify-content: center;
    line-height: 0;
    width: 100%;
    width: 100%;

    .carousel-navigation-item {
      max-width: 19px;
      min-width: 19px;

      &.active {
        .navigation-item-button {
          &:after {
            background-color: $dark-grayish-brown;
          }
        }
      }

      .navigation-item-button {
        align-items: center;
        display: flex;
        height: 19px;
        justify-content: center;
        width: 100%;

        &:after {
          border-radius: 50%;
          border: solid 1px $dark-grayish-brown;
          content: '';
          display: block;
          height: 5px;
          transition: background-color 300ms;
          width: 5px;
        }
      }
    }
  }
}
