@mixin z-moon {
  z-index: 9999;
}

@mixin z-space {
  z-index: 999; 
}

@mixin z-sky {
  z-index: 99;
}

@mixin z-hills {
  z-index: 9; 
}

@mixin z-floor {
  z-index: 0; 
}

@mixin z-underground {
  z-index: -9; 
}

@mixin z-bedrock {
  z-index: -99; 
}
