#grid {
  .column-line {
    background-color: cyan;
    display: block;
    height: 100vh;
    opacity: 0.25;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: mobile-grid(1);
    z-index: 9999999999;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      width: tablet-grid(1);
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      width: desktop-grid(1);
    }

    &.column-grid-1 {
      left: #{map-get($mobile, 'outer')}px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: #{map-get($tablet, 'outer')}px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: #{map-get($desktop, 'outer')}px;
      }
    }

    &.column-grid-2 {
      left: mobile-grid(1, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(1, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(1, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-3 {
      left: mobile-grid(2, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(2, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(2, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-4 {
      left: mobile-grid(3, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(3, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(3, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-5 {
      left: mobile-grid(4, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(4, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(4, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-6 {
      left: mobile-grid(5, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(5, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(5, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-7 {
      left: mobile-grid(6, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(6, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(6, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-8 {
      left: mobile-grid(7, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(7, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(7, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-9 {
      left: mobile-grid(8, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(8, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(8, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-10 {
      left: mobile-grid(9, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(9, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(9, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-11 {
      left: mobile-grid(10, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(10, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(10, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }

    &.column-grid-12 {
      left: mobile-grid(11, map-get($mobile, 'outer') + map-get($mobile, 'gutter'));

      @media (min-width: #{map-get($tablet, 'width')}px) {
        left: tablet-grid(11, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        left: desktop-grid(11, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
      }
    }
  }
}
