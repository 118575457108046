#event-alert-list {
  display: none;
  height: 0;
  opacity: 0;
  overflow: hidden;
  width: 0;
}

.event-alert-container {
  @include z-space;
  background-color: $dark-grayish-orange;
  left: 0;
  position: fixed;
  top: 0;
  transition: top 400ms;
  width: 100vw;

  &.hide {
    top: -120%;
  }

  .event-alert-item {
    @include saa-series-fd;
    color: $very-light-grayish-orange;
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    margin: 0 auto;
    padding: 12px 0 10px;
    text-align: center;
    width: mobile-grid(12);

    @media (min-width: #{map-get($tablet, 'width')}px) {
      padding: 14px 0;
      width: tablet-grid(12);
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      width: desktop-grid(12);
    }

    br {
      @media (min-width: #{map-get($tablet, 'width')}px) {
        content: ' ';
        display: inline;
        padding: 0 3px;
      }
    }

    .close-envent-alert {
      display: block;
      height: 12px;
      position: absolute;
      right: #{map-get($mobile, 'outer')}px;
      top: 50%;
      transform: translateX(100%) translateY(-50%);
      width: 12px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        right: #{map-get($tablet, 'outer')}px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        right: 20px;
        transform: translateY(-50%);
      }

      svg {
        @include responsive-svg;
        fill: $light-grayish-orange;
      }
    }
  }
}
