.post-type-archive-happening {
  .happenings-archive-highlights {
    height: calc(100vh - #{map-get($navigation-header, 'height-mobile')}px);

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      height: calc(100vh - #{map-get($navigation-header, 'height-desktop')}px);
    }

    .happenings-highlights-carousel {
      height: inherit;
      position: relative;
      width: 100%;

      .highlights-carousel-item {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid 1px $very-dark-gray;
        display: flex;
        height: calc(100% - 26px  - 2px);
        justify-content: center;
        left: #{map-get($mobile, 'outer')}px;
        opacity: 0;
        position: absolute;
        top: 0;
        width: calc(100% - #{map-get($mobile, 'outer') * 2}px - 2px);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          height: calc(100% - 41px  - 2px);
          left: #{map-get($tablet, 'outer')}px;
          width: calc(100% - #{map-get($tablet, 'outer') * 2}px - 2px);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          height: calc(100% - 35px  - 2px);
          justify-content: flex-start;
          left: 25px;
          width: calc(100% - 50px - 2px);
        }

        &.active {
          opacity: 1;

          .carousel-item-link {
            display: block;
          }

          .carousel-item-content {
            display: block;
          }
        }

        .carousel-item-link {
          @include z-hills;
          background: transparent;
          display: none;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        .carousel-item-content {
          @include z-hills;
          display: none;
          position: relative;
          transform: translateY(-#{(map-get($navigation-header, 'height-mobile') / 2) - ((26 + 2) / 2)}px);
          width: mobile-grid(8);

          @media (min-width: #{map-get($tablet, 'width')}px) {
            transform: translateY(-#{(map-get($navigation-header, 'height-mobile') / 2) - ((41 + 2) / 2)}px);
            width: tablet-grid(6);
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            margin-left: desktop-grid(1, map-get($desktop, 'gutter') + 28);
            transform: translateY(-#{(map-get($navigation-header, 'height-desktop') / 2) - ((35 + 2) / 2)}px);
            width: desktop-grid(4);
          }

          .item-content-date {
            @include saa-series-fd;
            color: $very-light-grayish-orange;
            font-size: 14px;
            letter-spacing: 3.5px;
            line-height: 20px;
            margin-bottom: 22px;
            text-align: center;
          }

          .item-content-name {
            @include tiempos-headline;
            color: $light-grayish-red;
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 30px;
            text-align: center;

            i,
            em {
              @include tiempos-headline-italic;
            }
          }

          .item-content-time {
            @include tiempos-text;
            color: $light-grayish-red;
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 30px;
            text-align: center;
          }

          .item-content-link-container {
            padding-top: 93px;
            position: relative;
            text-align: center;

            &:after {
              background-color: $very-dark-grayish-orange;
              content: '';
              display: block;
              height: 63px;
              left: 50%;
              position: absolute;
              top: 0;
              transform: translateX(-50%);
              width: 1px;
            }

            .item-content-link {
              @include saa-series-fd;
              color: $very-dark-green;
              display: inline-block;
              font-size: 10px;
              letter-spacing: 2.5px;
              line-height: 14px;
              width: fit-content;
            }
          }
        }
      }

      .carousel-navigation-last,
      .carousel-navigation-next {
        height: 21px;
        position: absolute;
        top: 50%;
        width: 32px;

        svg {
          @include responsive-svg;
          fill: $very-light-grayish-orange;
        }
      }

      .carousel-navigation-last {
        left: #{map-get($mobile, 'outer') + 10}px;
        transform: translateY(-50%) rotate(180deg);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          left: #{map-get($tablet, 'outer') + 10}px;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          left: #{map-get($desktop, 'outer')}px;
        }
      }

      .carousel-navigation-next {
        right: #{map-get($mobile, 'outer') + 10}px;
        transform: translateY(-50%);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          right: #{map-get($tablet, 'outer') + 10}px;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          right: #{map-get($desktop, 'outer')}px;
        }
      }
    }
  }
}
