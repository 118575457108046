.page-template-visit-us {
  .visit-us-cover {
    background-color: $light-grayish-red;
    height: calc(100vh - #{map-get($navigation-header, 'height-mobile')}px - 15px);
    width: 100%;

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      height: calc(100vh - #{map-get($navigation-header, 'height-mobile')}px - 15px);
    }

    .visit-us-cover-carousel {
      height: inherit;
      padding-top: 15px;
      position: relative;
      width: inherit;

      .cover-carousel-item {
        height: calc(100% - 15px);
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 15px;
        transform: translateX(-50%);
        width: mobile-grid(12);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          height: 100%;
          top: 0;
          width: tablet-grid(12);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          width: calc(100% - 50px);
        }

        &.active {
          opacity: 1;
        }

        .cover-carousel-item-image {
          background-position: center;
          background-size: cover;
          height: calc(100% - 125px);
          width: 100%;

          @media (min-width: #{map-get($tablet, 'width')}px) {
            height: calc(100% - 71px);
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            height: calc(100% - 83px);
          }
        }

        .cover-carousel-item-description {
          align-items: center;
          display: flex;
          height: 125px;
          justify-content: center;
          width: 100%;

          @media (min-width: #{map-get($tablet, 'width')}px) {
            height: 71px;
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            height: 83px;
          }

          .description-content {
            @include tiempos-text-italic;
            color: $very-soft-orange;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            width: mobile-grid(8, map-get($mobile, 'gutter') * 2);

            @media (min-width: #{map-get($tablet, 'width')}px) {
              width: tablet-grid(10);
            }

            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              width: desktop-grid(10);
            }
          }
        }
      }

      .carousel-navigation-last,
      .carousel-navigation-next {
        bottom: 52px;
        display: block;
        height: 21px;
        position: absolute;
        width: 32px;

        @media (min-width: #{map-get($tablet, 'width')}px) {
          bottom: 25px;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          bottom: 31px;
        }

        svg {
          @include responsive-svg;
          fill: $dark-grayish-orange;
        }
      }

      .carousel-navigation-last {
        left: #{map-get($mobile, 'outer')}px;

        @media (min-width: #{map-get($tablet, 'width')}px) {
          left: #{map-get($tablet, 'outer')}px;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          left: #{map-get($desktop, 'outer')}px;
        }

        svg {
          transform: rotate(180deg);
        }
      }

      .carousel-navigation-next {
        right: #{map-get($mobile, 'outer')}px;

        @media (min-width: #{map-get($tablet, 'width')}px) {
          right: #{map-get($tablet, 'outer')}px;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          right: #{map-get($desktop, 'outer')}px;
        }
      }
    }
  }

  .visit-us-about {
    background-color: $light-grayish-red;
    border-top: solid 1px $very-dark-gray;
    padding: 77px 0 30px;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      padding: 100px 0 32px;
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      display: flex;
      flex-wrap: wrap;
      padding: 100px 0 42px;
    }

    .visit-us-about-informations {
      padding: 0 #{map-get($mobile, 'outer')}px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        padding: 0 #{map-get($tablet, 'outer')}px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin-left: #{map-get($desktop, 'outer')}px;
        padding: 0;
        width: desktop-grid(7);
      }

      .about-informations-title {
        @include tiempos-headline;
        color: $dark-grayish-orange;
        font-size: 24px;
        line-height: 32px;
        width: mobile-grid(11);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          font-size: 36px;
          line-height: 42px;
          margin-left: tablet-grid(1, map-get($tablet, 'gutter'));
          width: tablet-grid(10);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          margin-left: 0;
          margin-left: 0;
          width: desktop-grid(6);
        }

        &:not(:last-child) {
          margin-bottom: 40px;

          @media (min-width: #{map-get($tablet, 'width')}px) {
            margin-bottom: 50px;
          }
        }

        i,
        em {
          @include tiempos-headline-italic;
        }
      }

      .about-informations-content {
        @include tiempos-text;
        color: $very-soft-orange;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 100px;
        padding-left: mobile-grid(1, map-get($mobile, 'gutter'));
        width: mobile-grid(11, map-get($mobile, 'gutter'));

        @media (min-width: #{map-get($tablet, 'width')}px) {
          font-size: 22px;
          line-height: 28px;
          margin-left: tablet-grid(2, map-get($tablet, 'gutter'));
          padding-left: 0;
          width: tablet-grid(9);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          margin-bottom: 160px;
          margin-left: desktop-grid(1, map-get($desktop, 'gutter'));
          width: desktop-grid(5, map-get($desktop, 'gutter') * 2);
        }

        p {
          &:not(:last-child) {
            margin-bottom: 22px;
          }

          i,
          em {
            @include tiempos-headline-italic;
          }
        }
      }
    }

    .visit-us-about-contacts {
      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin-bottom: 160px;
        margin-left: #{map-get($desktop, 'gutter')}px;
        width: desktop-grid(5);
      }

      &:not(:last-child) {
        margin-bottom: 100px;
      }

      .about-contacts-address {
        @include saa-series-fd;
        color: $dark-grayish-orange;
        font-size: 14px;
        letter-spacing: 3.5px;
        line-height: 20px;
        margin: 0 auto 30px;
        padding-bottom: 93px;
        position: relative;
        text-align: center;
        width: mobile-grid(12);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          width: tablet-grid(6);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          width: desktop-grid(3, map-get($desktop, 'gutter') * 2);
        }

        &:after {
          background-color: $very-dark-grayish-orange;
          bottom: 0;
          content: '';
          height: 63px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 1px;
        }
      }

      .about-contacts-social,
      .about-contacts-email,
      .about-contacts-phone {
        margin: 0 auto 30px;
        text-align: center;
        width: mobile-grid(12);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          width: tablet-grid(12);
        }

        @media (min-width: #{map-get($tablet, 'width')}px) {
          width: 100%;
        }

        &:last-child {
          margin: 0 auto;
        }

        .contacts-link {
          @include saa-series-fd;
          color: $soft-orange;
          display: inline-block;
          font-size: 10px;
          letter-spacing: 2.5px;
          line-height: 14px;
          width: fit-content;
        }
      }
    }

    .visit-us-about-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 365px;
      margin: 0 auto;
      width: mobile-grid(12);

      @media (min-width: #{map-get($tablet, 'width')}px) {
        width: tablet-grid(12);
      }

      @media (min-width: #{map-get($desktop, 'width')}px) {
        width: calc(100% -  50px);
      }
    }
  }
}
