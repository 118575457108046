@font-face {
  font-family: SaaSeriesFD;
  src: url(SaaSeriesFD.eot);
  src: url('#{$fonts-path}SaaSeriesFD.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}SaaSeriesFD.woff2') format('woff2'),
    url('#{$fonts-path}SaaSeriesFD.woff') format('woff'),
    url('#{$fonts-path}SaaSeriesFD.ttf') format('truetype'),
    url('#{$fonts-path}SaaSeriesFD.svg#SaaSeriesFD') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: TiemposHeadline;
  src: url('#{$fonts-path}TiemposHeadline-Light.eot');
  src: url('#{$fonts-path}TiemposHeadline-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}TiemposHeadline-Light.woff2') format('woff2'),
    url('#{$fonts-path}TiemposHeadline-Light.woff') format('woff'),
    url('#{$fonts-path}TiemposHeadline-Light.ttf') format('truetype'),
    url('#{$fonts-path}TiemposHeadline-Light.svg#TiemposHeadline-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: TiemposHeadline;
  src: url('#{$fonts-path}TiemposHeadline-LightItalic.eot');
  src: url('#{$fonts-path}TiemposHeadline-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}TiemposHeadline-LightItalic.woff2') format('woff2'),
    url('#{$fonts-path}TiemposHeadline-LightItalic.woff') format('woff'),
    url('#{$fonts-path}TiemposHeadline-LightItalic.ttf') format('truetype'),
    url('#{$fonts-path}TiemposHeadline-LightItalic.svg#TiemposHeadline-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: TiemposText;
  src: url('#{$fonts-path}TiemposText-Regular.eot');
  src: url('#{$fonts-path}TiemposText-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}TiemposText-Regular.woff2') format('woff2'),
    url('#{$fonts-path}TiemposText-Regular.woff') format('woff'),
    url('#{$fonts-path}TiemposText-Regular.ttf') format('truetype'),
    url('#{$fonts-path}TiemposText-Regular.svg#TiemposText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: TiemposText;
  src: url('#{$fonts-path}TiemposText-RegularItalic.eot');
  src: url('#{$fonts-path}TiemposText-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-path}TiemposText-RegularItalic.woff2') format('woff2'),
    url('#{$fonts-path}TiemposText-RegularItalic.woff') format('woff'),
    url('#{$fonts-path}TiemposText-RegularItalic.ttf') format('truetype'),
    url('#{$fonts-path}TiemposText-RegularItalic.svg#TiemposText-Regular') format('svg');
  font-weight: normal;
  font-style: italic;
}
