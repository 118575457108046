.page-template-landing {
  .landing-cover {
    height: calc(100vh - #{map-get($navigation-header, 'height-mobile')}px);
    margin: 0 auto;
    padding-bottom: 26px;
    position: relative;
    width: mobile-grid(12);

    @media (min-width: #{map-get($tablet, 'width')}px) {
      padding-bottom: 32px;
      width: tablet-grid(12);
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      height: calc(100vh - #{map-get($navigation-header, 'height-desktop')}px);
      padding-bottom: 40px;
      width: calc(100% - 2 * 21px);
    }

    .landing-cover-carousel-image {
      height: 100%;
      position: relative;
      width: 100%;

      .carousel-image-item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid 1px $very-dark-gray;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &.active {
          opacity: 1;
        }
      }
    }

    .landing-cover-title {
      @include saa-series-fd;
      box-sizing: border-box;
      color: $very-pale-orange;
      display: block;
      font-size: 28px;
      left: 50%;
      letter-spacing: 7px;
      line-height: 44px;
      padding: 0 #{map-get($mobile, 'gutter')}px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        font-size: 50px;
        letter-spacing: 12.5px;
        line-height: 60px;
        padding: 0;
        width: tablet-grid(8, map-get($tablet, 'gutter') * 2);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        width: desktop-grid(10, map-get($desktop, 'gutter') * 2);
      }
    }

    .reserve-table-container {
      bottom: 27px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: fit-content;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        bottom: 33px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        bottom: 41px;
      }

      .reserve-table-link {
        @include saa-series-fd;
        background-color: $light-grayish-red;
        color: $dark-grayish-orange;
        display: block;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 1;
        padding: 14px 22px 11px;
      }
    }
  }

  .landing-presentation {
    background-color: $light-grayish-orange;
    border-top: solid 1px $very-dark-gray;
    padding: 118px 0 121px;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      padding: 96px 0 96px;
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      padding: 96px 0 149px;
    }

    .landing-presentation-title {
      @include tiempos-headline;
      color: $dark-grayish-orange;
      display: block;
      font-size: 36px;
      line-height: 42px;
      margin: 0 auto;
      text-align: center;
      width: mobile-grid(12);

      @media (min-width: #{map-get($tablet, 'width')}px) {
        width: tablet-grid(7, map-get($tablet, 'gutter') * 2);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        width: desktop-grid(5);
      }

      &:not(:last-child) {
        margin-bottom: 40px;
      }

      i,
      em {
        @include tiempos-headline-italic;
      }
    }

    .landing-presentation-content {
      p {
        @include tiempos-text;
        color: $slightly-desaturated-red;
        font-size: 22px;
        line-height: 28px;
        margin: 0 auto 20px;
        text-align: center;
        width: mobile-grid(10, map-get($mobile, 'gutter') * 2);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          box-sizing: border-box;
          padding: 0 #{map-get($tablet, 'gutter')}px;
          width: tablet-grid(10);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          padding: 0 #{map-get($desktop, 'gutter')}px;
          width: desktop-grid(6);
        }

        &:last-child {
          margin: 0 auto;
        }

        i,
        em {
          @include tiempos-text-italic;
        }
      }
    }
  }

  .landing-welcome-banner {
    background-color: $light-grayish-orange;
    border-bottom: solid 1px $very-dark-gray;
    padding-bottom: 25px;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      padding-bottom: 42px;
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      padding-bottom: 64px;
    }

    .landing-welcome-banner-content {
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      height: 364px;
      justify-content: center;
      margin: 0 auto;
      width: mobile-grid(12);

      @media (min-width: #{map-get($tablet, 'width')}px) {
        width: tablet-grid(12);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        height: 366px;
        width: calc(100% - 2 * 21px);
      }

      .landing-welcome-banner-title {
        @include saa-series-fd;
        color: $very-pale-orange;
        font-size: 28px;
        letter-spacing: 7px;
        line-height: 44px;
        text-align: center;
        width: mobile-grid(10);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          width: tablet-grid(10);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          font-size: 50px;
          letter-spacing: 12.5px;
          line-height: 60px;
          width: desktop-grid(10);
        }
      }
    }
  }

  .menus-list {
    &.menus-list-anchor {
      padding-top: 30px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        padding-bottom: 20px;
        padding-top: 100px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        padding-bottom: 87px;
        padding-top: 58px;
      }

      .menu-item {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
