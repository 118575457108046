.private-events {
  padding-bottom: 32px;
  padding-top: 100px;

  @media (min-width: #{map-get($tablet, 'width')}px) {
    padding-bottom: 37px;
  }

  @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
    padding-bottom: 55px;
    padding-top: 49px;
  }

  .private-events-content {
    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .private-events-informations {
      width: mobile-grid(12);
      margin: 0 auto 100px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        width: tablet-grid(12);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin-bottom: 0;
        margin-right: #{map-get($desktop, 'outer')}px;
        max-width: tablet-grid(6);
        min-width: tablet-grid(6);
        order: 2;
        width: 100%;
      }

      .private-events-title {
        @include tiempos-headline;
        color: $dark-grayish-orange;
        font-size: 36px;
        margin: 0 auto 40px;
        text-align: center;
        width: 100%;
      }

      .private-events-subtitle {
        @include tiempos-headline;
        color: $dark-grayish-orange;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 34px;
        text-align: center;
      }

      .private-events-title,
      .private-events-subtitle {
        i,
        em {
          @include tiempos-headline-italic;
        }
      }

      .private-events-link-wrapper {
        margin: 0 auto;
        padding-top: 93px;
        position: relative;
        width: 100%;

        &:after {
          background-color: $very-dark-grayish-orange;
          content: '';
          height: 63px;
          left: 50%;
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          width: 1px;
        }

        .private-events-link {
          @include saa-series-fd;
          color: $very-dark-grayish-orange;
          display: block;
          font-size: 10px;
          letter-spacing: 2.5px;
          line-height: 14px;
          margin: 0 auto;
          transition: color 300ms;
          width: fit-content;

          &:hover {
            color: $soft-orange;
          }
        }
      }
    }

    .private-events-image-container {
      width: mobile-grid(12);
      margin: 0 auto;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        width: tablet-grid(12);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin: 0;
        max-width: tablet-grid(6);
        min-width: tablet-grid(6);
        order: 1;
        width: 100%;
      }

      .private-events-image {
        background-position: center center;
        background-size: cover;
        width: inherit;
        height: 469px;

        @media (min-width: #{map-get($tablet, 'width')}px) {
          height: 465px;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          height: 629px;
        }
      }
    }
  }
}
