#navigation-header {
  @include z-sky;
  background-color: $light-grayish-red;
  height: #{map-get($navigation-header, 'height-mobile')}px;
  left: 0;
  position: fixed;
  top: 0;
  transition: top 200ms;
  width: 100%;

  @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
    height: #{map-get($navigation-header, 'height-desktop')}px;
  }

  &.activated-menu {
    .header-container {
      background-color: $very-dark-grayish-orange;
    }

    .activate-menu,
    .logo-link {
      svg {
        fill: $light-grayish-orange;
      }
    }

    .activate-menu {
      svg {
        fill: $light-grayish-orange;

        .top {
          transform-origin: -33.33% 50%;
          transform: translateY(-7px) rotate(45deg);
        }

        .middle {
          opacity: 0;
          transform-origin: 50% 50%;
          transform: rotate(45deg);
        }

        .bottom {
          transform-origin: -33.33% 50%;
          transform: translateY(7px) rotate(-45deg);
        }
      }
    }

    .reserve-link {
      @media (min-width: #{map-get($tablet, 'width')}px) {
        display: none;
      }
    }

    #navigation {
      left: 0;
    }
  }

  &.no-transition {
    #navigation {
      transition: none;
    }
  }

  .activate-menu,
  .logo-link {
    @include z-space;
    display: block;
    position: absolute;

    svg {
      @include responsive-svg;
      fill: $dark-grayish-orange;
      transition: fill 400ms;
    }
  }

  &.with-alert {
    top: #{map-get($event-alert, 'height-mobile')}px;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      top: #{map-get($event-alert, 'height-tablet')}px;
    }

    #navigation {
      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        top: #{map-get($event-alert, 'height-tablet')}px;
      }
    }
  }

  .activate-menu {
    height: 20px;
    left: #{map-get($mobile, 'outer')}px;
    top: 24px;
    width: 20px;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      left: #{map-get($tablet, 'outer')}px;
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      display: none;
    }

    svg {
      .top {
        transform-origin: -33.33% 50%;
        transform: translateY(0) rotate(0);
      }

      .middle {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: rotate(0);
      }

      .bottom {
        transform-origin: -33.33% 50%;
        transform: translateY(0) rotate(0);
      }

      .top,
      .bottom {
        transition: transform 400ms;
      }

      .middle {
        transition: transform 400ms, opacity 400ms;
      }
    }
  }

  .logo-link {
    height: 32px;
    left: 50%;
    top: 16px;
    transform: translateX(-50%);
    width: 148px;

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      @include z-moon;
      top: 24px;
    }
  }

  .reserve-link {
    display: none;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      @include saa-series-fd;
      color: $dark-grayish-orange;
      display: block;
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 1.333;
      position: absolute;
      right: #{map-get($tablet, 'outer')}px;
      top: 32px;
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      display: none;
    }
  }

  #navigation {
    @include z-sky;
    background-color: $very-dark-grayish-orange;
    height: 100vh;
    left: -120%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transition: left 200ms, top 200ms;
    width: 100%;

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      background-color: $light-grayish-red;
      height: inherit;
      left: 0;
      margin-left: #{map-get($desktop, 'outer')}px;
      padding-top: 34px;
      position: fixed;
      top: 0;
      transition: none;
      width: desktop-grid(12, 10);
    }

    .navigation-list {
      min-height: #{map-get($mobile, 'height')}px;
      padding-top: 198px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        min-height: #{map-get($tablet, 'height')}px;
        padding-top: 290px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        min-height: initial;
        padding-top: initial;
        width: 100%;
      }

      .navigation-item {
        @include saa-series-fd;
        color: $very-light-grayish-orange;
        display: block;
        font-size: 14px;
        letter-spacing: 3.5px;
        line-height: 1;
        margin-bottom: 26px;
        text-align: center;

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          color: $dark-grayish-orange;
          font-size: 12px;
          margin-bottom: 0;
          text-align: left;
          width: fit-content;
        }

        &:first-child {
          margin-bottom: 36px;

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            margin-bottom: 0;
            margin-right: 30px;
          }
        }

        &:nth-child(3) {
          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            margin-right: 42px;
          }
        }

        &:nth-last-child(2) {
          display: none;

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            display: block;
            // margin-left: auto;
            margin-right: 60px;
          }
        }

        &:last-child {
          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }

      .navigation-sublist {
        margin-bottom: 90px;

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          display: none;
        }

        .navigation-subitem {
          @include tiempos-headline;
          color: $grayish-red;
          font-size: 28px;
          margin-bottom: 18px;
          text-align: center;

          &:not(:last-child) {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}
