#main-container {
  background-color: $light-grayish-red;
  min-height: 100vh;
  padding-top: #{map-get($navigation-header, 'height-mobile')}px;
  transition: padding-top 200ms;

  @media (min-width: #{map-get($desktop, 'width')}px) {
    padding-top: #{map-get($navigation-header, 'height-desktop')}px;
  }

  &.with-alert {
    padding-top: #{map-get($navigation-header, 'height-mobile') + map-get($event-alert, 'height-mobile')}px;
  }
}