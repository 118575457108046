.page-template-menus {
  background-color: $light-grayish-red;

  .menus-container {
    .menus-cover {
      margin-bottom: 30px;
      padding: 0 #{map-get($mobile, 'outer')}px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        margin-bottom: 49px;
        padding: 0 #{map-get($tablet, 'outer')}px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        padding: 0 #{map-get($desktop, 'outer')}px;
      }

      .menus-cover-content {
        align-items: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-wrap: nowrap;
        height: 400px;
        justify-content: center;
        position: relative;
        width: 100%;

        .menus-cover-image-carousel {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          .image-carousel-item {
            background-position: center;
            background-size: cover;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;

            &.active {
              opacity: 1;
            }
          }
        }

        .menus-cover-title {
          @include saa-series-fd;
          @include z-hills;
          color: $very-pale-orange;
          font-size: 28px;
          letter-spacing: 7px;
          line-height: 44px;
          position: relative;
          text-align: center;
          width: mobile-grid(8);

          @media (min-width: #{map-get($tablet, 'width')}px) {
            line-height: 34px;
            width: tablet-grid(10);
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            font-size: 50px;
            letter-spacing: 12.5px;
            line-height: 60px;
            width: desktop-grid(10);
          }
        }
      }
    }
  }
}
