.single-happening {
  .happening-cover {
    background-color: $light-grayish-red;
    padding: 10px #{map-get($mobile, 'outer')}px 33px;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      padding: 0 #{map-get($tablet, 'outer')}px 103px;
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      border-bottom: solid 1px $very-dark-gray;
      display: flex;
      justify-content: space-between;
      padding: 0 30px 30px #{map-get($desktop, 'outer')}px;
      width: 100%;
    }

    .happening-cover-image-container {
      margin-bottom: 40px;
      width: 100%;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        margin-bottom: 71px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin-bottom: 0;
        max-width: 50vw;
        min-width: 50vw;
        order: 2;
        padding: 0 30px 0 #{map-get($desktop, 'gutter') / 2}px;
      }

      .happening-cover-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 418px;

        @media (min-width: #{map-get($tablet, 'width')}px) {
          height: 435px;
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          height: auto;
          min-height: calc(100vh - #{map-get($navigation-header, 'height-desktop')}px - 30px);
          width: 100%;
        }
      }
    }

    .happening-cover-text-container {
      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: desktop-grid(6);
        min-width: desktop-grid(6);
        order: 1;
        padding-right: desktop-grid(1, map-get($desktop, 'gutter'));
      }

      .happening-cover-title {
        @include tiempos-headline;
        color: $dark-grayish-orange;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
        width: mobile-grid(11);

        @media (min-width: #{map-get($tablet, 'width')}px) {
          font-size: 36px;
          line-height: 42px;
          margin-left: tablet-grid(1, map-get($tablet, 'gutter'));
          width: tablet-grid(8);
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          margin-left: 0;
          width: desktop-grid(5);
        }

        i,
        em {
          @include tiempos-headline-italic;
        }
      }

      .happening-cover-content {
        padding-left: mobile-grid(1, map-get($mobile, 'gutter'));

        @media (min-width: #{map-get($tablet, 'width')}px) {
          padding-left: tablet-grid(3, map-get($tablet, 'gutter'));
        }

        @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
          padding-left: desktop-grid(1, map-get($desktop, 'gutter'));
        }

        p {
          color: $very-soft-orange;
          font-size: 18px;
          line-height: 22px;
          width: mobile-grid(10, map-get($mobile, 'gutter'));

          @media (min-width: #{map-get($tablet, 'width')}px) {
            font-size: 22px;
            line-height: 28px;
            width: tablet-grid(8);
          }

          @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
            width: desktop-grid(4);
          }

          &:not(:last-child) {
            margin-bottom: 56px;

            @media (min-width: #{map-get($tablet, 'width')}px) {
              margin-bottom: 66px;
            }

            @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
              margin-bottom: 80px;
            }
          }
        }
      }
    }

    .scroll-to-content {
      display: block;
      height: 32px;
      margin-left: mobile-grid(1, map-get($mobile, 'gutter'));
      margin-top: 35px;
      width: 21px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        margin-left: tablet-grid(3, map-get($tablet, 'gutter'));
        margin-top: 40px;
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin-left: desktop-grid(1, map-get($desktop, 'gutter'));
      }

      svg {
        @include responsive-svg;
        fill: $dark-grayish-orange;
      }
    }
  }

  .happening-content-container {
    padding-bottom: 60px;
    padding-top: 85px;

    @media (min-width: #{map-get($tablet, 'width')}px) {
      padding-bottom: 16px;
    }

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      padding-bottom: 0;
      padding-top: 100px;
    }

    .content-item-title {
      @include tiempos-headline;
      color: $dark-grayish-orange;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 56px;
      margin-left: #{map-get($mobile, 'outer')}px;
      width: mobile-grid(11);

      @media (min-width: #{map-get($tablet, 'width')}px) {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 66px;
        margin-left: tablet-grid(1, map-get($tablet, 'outer') + map-get($tablet, 'gutter'));
        width: tablet-grid(9);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin-bottom: 60px;
        margin-left: desktop-grid(2, map-get($desktop, 'outer') + map-get($desktop, 'gutter'));
        width: desktop-grid(7);
      }

      i,
      em {
        @include tiempos-headline-italic;
      }
    }

    .content-item-text-content {
      margin-bottom: 100px;
      padding: 0 #{map-get($mobile, 'outer')}px;

      @media (min-width: #{map-get($tablet, 'width')}px) {
        margin: 0 auto 100px;
        padding: 0;
        width: tablet-grid(10);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin: 0 auto 80px;
        padding: 0;
        width: desktop-grid(8);
      }

      p {
        @include tiempos-text;
        color: $very-soft-orange;
        font-size: 18px;
        line-height: 22px;
        width: mobile-grid(10, map-get($mobile, 'gutter'));

        @media (min-width: #{map-get($tablet, 'width')}px) {
          font-size: 22px;
          line-height: 28px;
          width: 100%;
        }

        &:not(:last-child) {
          margin-bottom: 56px;

          @media (min-width: #{map-get($tablet, 'width')}px) {
            margin-bottom: 66px;
          }
        }

        i,
        em {
          @include tiempos-text-italic;
        }
      }
    }

    .content-item-image {
      display: block;
      height: auto;
      margin: 0 auto 56px;
      width: mobile-grid(12);

      @media (min-width: #{map-get($tablet, 'width')}px) {
        margin: 0 auto 66px;
        width: tablet-grid(10);
      }

      @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
        margin: 0 auto 80px;
        width: desktop-grid(8);
      }
    }
  }
}
