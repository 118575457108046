.join-us {
  background-color: $light-grayish-red;
  border-bottom: solid 1px $very-dark-gray;
  padding: 100px 0;
  width: 100%;

  .join-us-title,
  .join-us-content,
  .join-us-link-container {
    width: mobile-grid(12);

    @media (min-width: #{map-get($tablet, 'width')}px) {
      width: tablet-grid(8);
    }
  }

  .join-us-title,
  .join-us-link-container {
    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      width: desktop-grid(4);
    }
  }

  .join-us-title {
    @include tiempos-headline;
    color: $dark-grayish-orange;
    font-size: 36px;
    line-height: 42px;
    margin: 0 auto 40px;
    text-align: center;
  }

  .join-us-content {
    @include tiempos-text;
    color: $slightly-desaturated-red;
    font-size: 22px;
    line-height: 28px;
    margin: 0 auto 30px;
    text-align: center;

    @media (min-width: #{map-get($tablet-landscape, 'width')}px) {
      width: desktop-grid(4, map-get($desktop, 'gutter') * 2);
    }

    &:last-child {
      margin: 0 auto;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }

    i,
    em {
      @include tiempos-text-italic;
    }
  }

  .join-us-link-container {
    margin: 0 auto;
    padding-top: 93px;
    position: relative;

    &:after {
      background-color: $very-dark-grayish-orange;
      content: '';
      display: block;
      height: 63px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 1px;
    }

    .join-us-link {
      @include saa-series-fd;
      color: $dark-grayish-orange;
      display: block;
      font-size: 10px;
      letter-spacing: 2.5px;
      line-height: 14px;
      margin: 0 auto;
      transition: color 300ms;
      width: fit-content;

      &:hover {
        color: $soft-orange;
      }
    }
  }
}
