@mixin reset-margin-padding {
  margin: 0;
  padding: 0;
}

@mixin saa-series-fd {
  font-family: SaaSeriesFD, sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin tiempos-headline {
  font-family: TiemposHeadline, serif;
  font-weight: 300;
  font-style: normal;
}

@mixin tiempos-headline-italic {
  font-family: TiemposHeadline, serif;
  font-weight: 300;
  font-style: italic;
}

@mixin tiempos-text {
  font-family: TiemposText, serif;
  font-weight: normal;
  font-style: normal;
}

@mixin tiempos-text-italic {
  font-family: TiemposText, serif;
  font-weight: normal;
  font-style: italic;
}

@mixin font-inherit {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@mixin responsive-svg {
  display: block;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}
