$dark-grayish-brown: #7D6D5E;
$dark-grayish-orange: #7E6D5F;
$grayish-lime-green: #C1CFCA;
$grayish-orange: #CABCB7;
$grayish-red: #CDBBB6;
$light-grayish-orange: #E4DFDB;
$light-grayish-red: #EFE8E8;
$light-grayish-yellow: #FDF4E2;
$pure-black: #000000;
$pure-white: #FFFFFF;
$slightly-desaturated-orange: #D1A077;
$slightly-desaturated-red: #C69B8F;
$soft-orange: #DA9E70;
$very-dark-gray: #707070;
$very-dark-grayish-orange: #7B6D61;
$very-dark-green: #013A2E;
$very-light-grayish-orange: #F4EFE7;
$very-pale-orange: #FFF4E0;
$very-soft-orange: #DDB4A6;