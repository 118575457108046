html {
  overflow-x: hidden;
  width: 100vw;
  @include reset-margin-padding;

  body {
    @include reset-margin-padding;
    @include tiempos-text;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    position: relative;
    width: 100%;

    div,
    footer,
    header,
    main,
    nav,
    section {
      box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include font-inherit;
      @include reset-margin-padding;
    }

    p {
      @include reset-margin-padding;
    }

    blockquote {
      @include reset-margin-padding;
    }

    ul {
      @include reset-margin-padding;
      box-sizing: border-box;
      list-style: none;

      li {
        list-style: none;
        @include reset-margin-padding;
      }
    }

    a {
      @include font-inherit;
      text-decoration: none;
    }

    button {    
      @include reset-margin-padding;
      appearance: button;
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      overflow: visible;
      text-transform: none;
    }
  }

  input {
    background: none;
    border: none;
    color: inherit;
    outline: none;
  }
}